





























































































































import ViewModel from '@/views/contact/Idea.ts';
export default ViewModel;
